import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import { Vue, Component, Prop } from 'vue-property-decorator';
import catalogFilterStore from '@/store/modules/catalogFilterStore';

@Component
export default class CatalogFacetsComponent extends Vue {
  public highestRole: number = APP_CONST.MINUS_ONE;
  public isAdmin: boolean = false;
  public minCompletionTime: number = 0;
  public maxCompletionTime: number = 0;

  @Prop({ default: {} })
  facets!: any;

  /* istanbul ignore next */
  beforeMount() {
    this.highestRole = Number(APP_UTILITIES.getCookie('highest_role'));
    this.isAdmin = this.highestRole !== null && this.highestRole < APP_CONST.SEVEN;
  }

  toggleNestedFacets(parent: any, children: any) {
    if (['Academic', 'Activities'].includes(parent.value)) {
      if (parent.selected) {
        children.forEach((element: any) => {
          element.selected = true;
        });
      }
      else {
        children.forEach((element: any) => {
          element.selected = false;
        });
      }
    }
  }

  capitalize(text: string) {
    return APP_UTILITIES.capitalize(text);
  }

  onFacetsChanged() {
    // Set timeout to allow the DOM to update before emitting the event
    // TODO: figure a better way to do this, not loving the setTimeout
    setTimeout(() => {
      this.$emit('facetsUpdated');
    }, 10);
  }

  clearAll() {
    this.$store.dispatch('catalogFilterStore/resetCatalogFilterDataAction', true);
    this.onFacetsChanged();
  }

  closeMobileFilters() {
    this.clearAll();
    this.$emit('filtersClosed');
  }

}
